import {
  isFuture,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  formatISO,
} from "date-fns";
import api_public from "services/api_public";
import api from "services/api";
import { NoSimTwoTone } from "@material-ui/icons";
import moment from "moment-timezone";

const hp = {
  is_login: () => {
    if (
      localStorage.getItem("id") === null ||
      localStorage.getItem("token") === null
    ) {
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("banks");
      return false;
    }
    return true;
  },
  get_user: async () => {
    let user = localStorage.getItem("user");
    let id = localStorage.getItem("id");
    if (user === null && id !== null) {
      let user_res = await api.get("profile");
      let user = user_res.data;
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
    return JSON.parse(user);
  },
  save_user: (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  },
  save_bank: (banks) => {
    localStorage.setItem("banks", JSON.stringify(banks));
  },
  get_banks: () => {
    let banks = localStorage.getItem("banks");
    return JSON.parse(banks);
  },
  is_investable: (user) => {
    if (
      !user.ic_type ||
      !user.ic_country ||
      !user.nric ||
      !user.nric_file ||
      !user.address_proof
    ) {
      return false;
    }
    return true;
  },
  get_day_left: (campaign) => {
    // console.log(campaign);
    let day_left = 0;
    let left_unit = "Days";
    // let expiry = new Date(campaign.expiry_datetime.replace(/ /g, "T"));
    let expiry = new Date(
      new moment.tz(campaign.expiry_datetime, "Asia/Singapore")
    );
    let now = new Date();
    // console.log("Expiry: " + moment.tz(expiry, "Asia/Singapore"));
    // console.log("Now: " + now);
    // console.log("Get day: " + expiry.getDay());
    // console.log("Get hours: " + expiry.getHours());
    // console.log("Get minutes: " + expiry.getMinutes());
    // console.log(campaign.investments_count);
    if (campaign.investments_count) {
      if (campaign.type == "donation") {
        if (isFuture(expiry)) {
          day_left = differenceInCalendarDays(expiry, now);
          // console.log("Days left in Days: " + day_left);
          if (day_left == 0) {
            day_left = differenceInHours(expiry, now);
            // console.log("Days left in Hours: " + day_left);
            left_unit = "Hours";
            if (day_left == 0) {
              day_left = differenceInMinutes(expiry, now);
              // console.log("Days left in Minutes: " + day_left);
              left_unit = "Minutes";
            }
          }
        }
      } else {
        day_left = 0;
      }
      // 2020-10-31 14:00:00 (expiry_datetime)
      // 2020-10-29 20:00:00 (release_datetime)
    }
    if (
      !campaign.investments_count ||
      parseInt(campaign.investments_count) < campaign.total_invest_amount
    ) {
      if (isFuture(expiry)) {
        day_left = differenceInCalendarDays(expiry, now);
        // console.log("Days left in Days: " + day_left);
        if (day_left == 0) {
          day_left = differenceInHours(expiry, now);
          // console.log("Days left in Hours: " + day_left);
          left_unit = "Hours";
          if (day_left == 0) {
            day_left = differenceInMinutes(expiry, now);
            // console.log("Days left in Minutes: " + day_left);
            left_unit = "Minutes";
          }
        }
      }
    }
    // console.log(day_left);
    return [day_left, left_unit];
  },
  is_prelaunch: (campaign) => {
    let _release_datetime = campaign.release_datetime.replace(/ /g, "T");
    let release_datetime = new Date(_release_datetime);
    if (isFuture(release_datetime)) {
      return true;
    }
    return false;
  },
  get_countries: async () => {
    let countries;
    if (localStorage.getItem("countries") === null) {
      let countries_res = await api_public.get("misc/country");
      countries = countries_res.data;
      localStorage.setItem("countries", JSON.stringify(countries));
    } else {
      countries = JSON.parse(localStorage.getItem("countries"));
    }
    return countries;
  },
  format_money: (amount) => {
    let formatter = new Intl.NumberFormat("en-US");
    return formatter.format(amount);
  },
  is_less_then_minimum: (campaign) => {
    if (
      campaign.minimum_invest_amount >
      campaign.total_invest_amount - parseInt(campaign.investments_count)
    ) {
      return true;
    } else {
      return false;
    }
  },
  get_maximum_investment: (campaign) => {
    return campaign.total_invest_amount - parseInt(campaign.investments_count);
  },
  format_payout_status: (status) => {
    if (status == "on-going") {
      return "On-Going";
    } else if (status == "paid") {
      return "Paid";
    } else {
      return "Delayed";
    }
  },
  wallet_available: () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (user.country == "SINGAPORE") {
      return false;
    }
    return true;
  },
  check_user_login: async (props) => {    
    if (localStorage.getItem("token") === null) {
      let {data} = await api.get("get-token");
      
      if (data.is_login) {
        hp.save_user(data.user);
        localStorage.setItem("id", data.login.id);
        localStorage.setItem("token", data.login.token);
  
        return data.user
      } else {
        return null
      }      
    }
  }
};

export default hp;
