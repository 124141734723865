import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'

import Banner from 'images/career.jpg'

class Page extends Component {


   componentDidMount() {
      window.scrollTo(0,0)
   }

   render() {
      return (
         <div id="career">
            <img src={Banner} width="100%" />

            <Container maxWidth="md" >
               <Typography variant="h3" component="h1" className="header-text spaceBig" >Career</Typography>

               <Typography>There are no job openings at the moment. However we are always on the lookout for spectacular persons to fill future roles. Should you want us to notice you, drop us your best CV to hello@kapitalboost.com. </Typography>
            </Container>

         </div>
      )
   }
}

export default Page
