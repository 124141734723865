import axios from "axios";

// export const url =  process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:5000/api/' : 'https://api.kapitalboost.com/api/'
let url = process.env.REACT_APP_API_URL;
// let url = "";
// if (process.env.REACT_APP_IS_STAGING) {
//   url = "https://stagingapi.kapitalboost.com/api";
// } else {
//   url =
//     process.env.NODE_ENV !== "production"
//       ? "http://localhost:8000/api/"
//       : "https://api.kapitalboost.com/api/";
// }

let instance = axios.create({
  baseURL: url,
  headers: {
    id: localStorage.getItem("id"),
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status == 401 && localStorage.getItem("id")) {
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error)
  }
);

export default instance;
