import React from "react";
import PropTypes from 'prop-types';

import './styles/legal.scss';

import BannerImageWeb from 'images/legal/header.png';
import BannerImageMobile from 'images/legal/header-m.png';
import { Link } from "react-router-dom";

import {
    Container,
    Grid,
    Typography
  } from "@material-ui/core";
import { Helmet } from "react-helmet";

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="px-2">{children}</div>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Legal() {
  const [value, setValue] = React.useState(window.location.hash.split("#")[1]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
          <title>Legal | Kapital Boost</title>
      </Helmet>
    
      <div data-page="legal">
        <div className="header">
          <img className="header-background" alt="header for about us" src={BannerImageMobile} srcSet={`${BannerImageMobile} 768w, ${BannerImageWeb} 1280w`} loading="lazy" />
        </div>

        <section className="blue tab">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="tab-header"
            >
              <Grid xs={4} align="center">
                <button className={`btn ${value === 'term' ? 'active':''}`} onClick={(e) => handleChange(e, 'term')}>Term of Use</button>
              </Grid>
              <Grid xs={4} align="center">
                <button className={`btn ${value === 'privacy-policy' ? 'active':''}`} onClick={(e) => handleChange(e, 'privacy-policy')}>Privacy Policy</button>
              </Grid>
              <Grid xs={4} align="center">
                <button className={`btn ${value === 'risk' ? 'active':''}`} onClick={(e) => handleChange(e, 'risk')}>Risk Statement</button>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="space">
          <Container>
            <TabPanel value={value} index={'term'}>
              <h1 className="title">Term of Use</h1>
              
              <Typography className="spaceBig" >This website and the contents herein do not constitute as any financial advice, investment advice or solicitation for the purposes of making financial investments in Singapore or other territories. Kapital Boost Pte. Ltd. is a firm specialising in the matching of opportunities between our registered members and small to medium-sized enterprises. With regard to these opportunities, the need and onus to do due diligence lies squarely with our members as we do not profess to advise on the same. All dealings and transactions are directly with the businesses, project owners or authorized agents we refer to our members.</Typography>
              <Typography className="spaceBig" >Kapital Boost Pte. Ltd. is not licensed and/or registered under the Securities & Futures Act of Singapore or the Financial Advisor's Act under the Monetary Authority of Singapore and thus cannot offer, solicit, advice or recommend the buying and selling of investment securities or any other financial and banking products from the public.</Typography>
              <Typography className="spaceBig" >Further, we state that in preparing and procuring the information within this document, whilst reasonable care is taken to ensure reliability and accuracy, Kapital Boost Pte. Ltd. and its affiliates, directors, employees, and partners provides no guarantee with regard to its content and completeness and does not accept any liability for losses which might arise from making use of this information.</Typography>
              <Typography className="spaceBig" >The opinions expressed in this document are those of Kapital Boost Pte. Ltd. at the time of writing. If nothing is indicated to the contrary, all figures are unaudited. The recipient is, in particular, recommended to check that the information provided is in line with his/her own circumstances with regard to any legal, regulatory, tax or other consequences, if necessary with the help of a professional advisor.</Typography>
              <Typography className="spaceBig" >This document may not be reproduced either in part or in full without the written permission of Kapital Boost Pte. Ltd. It is expressly not intended for persons who, due to their nationality or place of residence, are not permitted access to such information under local law.</Typography>
              <Typography className="spaceBig" >Every investment involves risk, especially with regard to fluctuations in value and return. Investments in foreign currencies involve the additional risk that the foreign currency might lose value against the investor's reference currency. It should be noted that historical returns and scenarios are no guarantee of future performance. Thank you for reading these notices. If you require additional information or clarifications from us on this matter, please email support@kapitalboost.com.</Typography>
            </TabPanel>

            <TabPanel value={value} index={'privacy-policy'}>
              <h1 className="title">Privacy Policy</h1>

              <Typography className="spaceBig">Kapital Boost takes your privacy very seriously. We ask that you read this privacy policy (the Policy) carefully as it contains important information about what to expect when Kapital Boost collects personal information about you and how Kapital Boost will use your personal data.</Typography>
              <Typography className="spaceBig">This Policy applies to information we collect on www.kapitalboost.com and its sub-domains about:</Typography>
              <Typography className="spaceBig spaceLeft">i) visitors to our website (the Site); and</Typography>
              <Typography className="spaceBig spaceLeft spaceBottom">ii) people who register their emails with us.</Typography>

              <Typography className="spaceBig bold">1. Information collected from all visitors to our website</Typography>
              <Typography className="spaceBottom">We will obtain personal data about you when you visit us. When you visit us, we may monitor the use of this Site through the use of cookies and similar tracking devices. For example, we may monitor the number of times you visit our Site or which pages you go to. This information helps us to build a profile of our users. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually. Please see further the section on Our Use of Cookies below.</Typography>

              <Typography className="spaceBig bold">2. Information collected from users who register their emails with us</Typography>
              <Typography className="spaceBig spaceLeft">(a) sending you marketing materials and contacting you about products and services that may be of interest to you;</Typography>
              <Typography className="spaceBig spaceLeft">(b) carrying out due diligence and other screening activities, or to prevent or investigate any fraud or unlawful activity;</Typography>
              <Typography className="spaceBig spaceLeft">(c) storing, hosting, backing up of personal data, whether within or outside of Singapore,</Typography>
              <Typography className="spaceBig">(collectively the “Purposes”).</Typography>
              <Typography className="spaceBig bold">Withdraw consent to receive marketing messages</Typography>
              <Typography className="spaceBig">If you wish to withdraw consent to receive marketing materials, please send an email message to support@kapitalboost.com with the subject UNSUBSCRIBE or opt-out directly from Mailchimp newsletter.</Typography>
              <Typography className="spaceBig bold">Withdraw consent to the use of personal data</Typography>
              <Typography className="spaceBig spaceBottom">If you wish to withdraw your consent to the use of your personal data to Kapital Boost and delete/de-activate your account, please send message to us via <Link to="/contact-us">contact us</Link> page or send email message to support@kapitalboost.com with the subject DE-ACTIVATE ACCOUNT</Typography>

              <Typography className="spaceBig bold">3. Our use of cookies</Typography>
              <Typography className="spaceBig spaceBottom">Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. The information is used to track visitor use of the Site and to compile statistical reports on Site activity. For further information about cookies visit www.aboutcookies.org or www.allaboutcookies.org. You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browser. However, in a few cases some of our Site features may not function if you remove cookies from your browser.</Typography>

              <Typography className="spaceBig bold">4. Identity Verification and KYC Onboarding Process</Typography>
              <Typography className="spaceBig spaceBottom">As part of our comprehensive Know Your Customer (KYC) onboarding procedure, Kapital Boost collects a range of identity verification data, encompassing your name, nationality, ID card details, and a selfie taken with the ID card. This entails the capture of photographs, both of you and your document, as well as the recording of video and sound during the verification procedure. To bolster the security and compliance of our services, Kapital Boost collaborates with Veriff (<a href="https://veriff.com" target={'_blank'}>veriff.com</a>), a trusted third-party partner. The collected user data, including the mentioned personal and sensitive information, will be securely processed by Veriff.</Typography>
              <Typography className="spaceBig">In our commitment to user privacy:</Typography>
              <Typography className="spaceBig spaceLeft bold">a. Data Security:</Typography>
              <Typography className="spaceBig spaceLeft">Kapital Boost and Veriff handle all personal and sensitive user data securely, employing modern cryptographic measures, such as the use of HTTPS during data transmission.</Typography>
              <Typography className="spaceBig spaceLeft bold">b. Data Sale Prohibition:</Typography>
              <Typography className="spaceBig spaceLeft">Kapital Boost and Veriff do not sell personal and sensitive user data. For clarity, the term "sale" refers to the exchange or transfer of such data to a third party for monetary consideration. It's important to note that user-initiated transfers, such as when using a feature within the app, are not considered as a sale. This underscores our commitment to safeguarding user information and ensuring transparent data practices.</Typography>
              {/* <Typography className="spaceBig spaceBottom">During our Know Your Customer (KYC) onboarding process, 
  Kapital Boost collects identity verification data, such as photographs taken from you and your document, and video and sound recording of the verification process. The data will be processed by our partner, Veriff (<a href="https://veriff.com" target={'_blank'}>veriff.com</a>). Veriff is a trusted third party assisting us in ensuring the security and compliance of our services.</Typography> */}
              
              <Typography className="spaceBig bold">5. How we protect your information</Typography>
              <Typography className="spaceBig spaceBottom">We are committed to maintaining the security of personal information. We have put in place appropriate security procedures and technical and organisational measures to safeguard your personal information such as securing our websites with SSL.</Typography>

              <Typography className="spaceBig bold">6. Access to your information and updating and correcting your information</Typography>
              <Typography className="spaceBig">Subject to the exceptions referred to in section 21(2) of the Personal Data Protection Act 2012 (No. 26 of 2012) of Singapore (PDP), you have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please send an email to support@kapitalboost.com. We may make a small charge for this service.</Typography>
              <Typography className="spaceBig spaceBottom">We want to ensure that your personal information is accurate and up to date. If any of the information that you have provided to Kapital Boost changes, for example if you change your email address, name or payment details, or if you wish to cancel your registration, please let us know the correct details by sending an email to support@kapitalboost.com. You may ask us, or we may ask you, to correct information you or we think is inaccurate, and you may also ask us to remove information which is inaccurate.</Typography>

              <Typography className="spaceBig bold">7. Non-disclosure</Typography>
              <Typography className="spaceBig">We do not sell, trade, or otherwise transfer to third parties your personally identifiable information. This does not include trusted third parties such as Xfers, who assist us in operating our website, conducting our business to perform certain functions in connection with the Purposes, or servicing you, as long as these parties agree to keep this information confidential.</Typography>
              <Typography className="spaceBig spaceBottom">We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours and others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</Typography>

              <Typography className="spaceBig bold">8. Links to other websites</Typography>
              <Typography className="spaceBig spaceBottom">Our Site may contains links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their own privacy policies.</Typography>

              <Typography className="spaceBig bold">9. Sale of business</Typography>
              <Typography className="spaceBig spaceBottom">If Kapital Boost's business is sold or integrated with another business your details may be disclosed to our advisers and any prospective purchasers and their advisers and will be passed on to the new owners of the business.</Typography>

              <Typography className="spaceBig bold">10. Changes to privacy policy</Typography>
              <Typography className="spaceBig spaceBottom">We keep our privacy policy under regular review. If we change our privacy policy we will post the changes on this page, and place notices on other pages of the Site, so that you may be aware of the information we collect and how we use it at all times. This privacy policy was last updated on 27th day of April 2017</Typography>

              <Typography className="spaceBig bold">11. How to contact us</Typography>
              <Typography className="spaceBig spaceBottom">We welcome your views about our Site and our privacy policy. If you would like to contact us with any queries or comments please send an email to <a href="mailto:support@kapitalboost.com">support@kapitalboost.com</a>.</Typography>
            </TabPanel>

            <TabPanel value={value} index={'risk'}>
              <h1 className="title">Risk Statement</h1>

              <Typography className="bold">Overview</Typography>
                      <Typography className="spaceBig">Kapital Boost enables you to invest directly in SMEs by funding, together with others, their business needs. A contractual relationship is established between each individual investor and the SME via Kapital Boost acting solely as an intermediary. </Typography>
                      <Typography className="spaceBig spaceBottom">It is important to remember that investments carry a degree of risk; in this case, there is a possibility of companies you provide funding to facing difficulties in repayment, potentially resulting in partial or total loss of your invested capital. When investing, your principal is at risk of loss.</Typography>
                      <Typography className="spaceBig spaceBottom">Also note that past performance does not guarantee future returns. Before making any investment decisions, investors should thoughtfully consider the risks of investing which includes but not limited to these outlined below.</Typography>

                      <Typography className="bold">Due diligence</Typography>
                      <Typography className="spaceBig">Kapital Boost performs a high standard of screening and due diligence on every SME before presenting them to our investors. In our initial screening process, SMEs seeking funding must meet specific minimum requirements, which include:</Typography>
                      <ol>
                          <li>
                            <Typography>Operating for a minimum of one year,</Typography>
                          </li>
                          <li>
                            <Typography>Achieving annual sales of at least SGD 100,000, and</Typography>
                          </li>
                          <li>
                            <Typography>Demonstrating a positive free cash flow over the past 12 months.</Typography>
                          </li>
                      </ol>

                      <Typography className="spaceBig spaceBottom">Furthermore, we conduct an in-depth examination of the SMEs' business profile and financial health, leveraging information provided by the company, such as bank records and insights obtained through management interviews</Typography>
                      <Typography className="spaceBig spaceBottom">While we strive to diligently identify and assess potential risks, it's crucial for investors not to rely solely on our analysis. We strongly recommend that investors conduct their own due diligence on the SMEs before making any investment decisions. All investments carry inherent risks, and past performance is not indicative of future results. The onus is on investors to carefully consider these risks and perform thorough research to make informed investment choices.</Typography>

                      <Typography className="bold">Diversification</Typography>
                      <Typography className="spaceBig spaceBottom">We emphasize the significance of diversifying your investments to mitigate portfolio risk. Our recommendation to Kapital Boost members is to distribute their investments across various funding campaigns, rather than consolidating all funds into one, in order to minimize the potential for losses. This strategy safeguards investors' capital from the impact of a single SME missing a payment or facing a default. We strongly advise consulting with your financial advisor to assess your investment capacity through Kapital Boost.</Typography>

                      <Typography className="bold">Missed payments</Typography>
                      <Typography className="spaceBig spaceBottom">Missed payments are treated with utmost seriousness at Kapital Boost. In the event of a missed scheduled payment, we take swift action by promptly contacting the SME to understand the circumstances and provide an opportunity for them to explain the situation. We believe in open communication and transparency.</Typography>
                      <Typography className="spaceBig spaceBottom">Investors are kept informed and updated regarding any missed payments and the steps being taken to address the situation. We maintain an ongoing channel of communication with the SME, sending regular reminders to fulfill their payment obligations until the outstanding amount is fully settled. Our priority is to ensure that investors' interests are safeguarded while working towards a resolution with the SME involved.</Typography>

                      <Typography className="bold blud">Defaults</Typography>
                      <Typography className="spaceBig">Payments overdue for more than 90 days will be categorized as defaults. In such instances, Kapital Boost will present investors with a set of available options for resolution:</Typography>

                      <ol>
                          <li>
                            <Typography><strong>Restructure the Funding Agreement:</strong> Investors may opt to revise the funding agreement to extend the payment term. This decision is contingent upon Kapital Boost's assessment of the company's potential cash flow. Additional securities from the business may be necessary to secure the rescheduled financing</Typography>
                          </li>
                          <li>
                            <Typography><strong>Legal Action for Negligence or Non-cooperation:</strong> In cases where there is evidence of investment fund mismanagement or non-cooperation from the SME, Kapital Boost may initiate legal actions against the business, its shareholders, directors, and commissioners. These legal measures may involve compelling the business to provide essential information crucial for evaluating its capacity to fulfill financial obligations or to facilitate payment via a Syariah-court led mediation.</Typography>
                          </li>
                      </ol>
                      <Typography className="spaceBottom">The selection of the appropriate option will be at the discretion of Kapital Boost, although it will be subject to investors' approval. </Typography>
                      <Typography className="spaceBottom">In the event of default, there is a possibility investors may not be able to recover any of their principal investments. </Typography>

                      <Typography className="bold">Platform failure</Typography>
                      <Typography className="spaceBig">In the event Kapital Boost discontinues its operations as a crowdfunding platform, SMEs remain obligated to fulfil their financial commitments to investors. All financing agreements hold legal validity and would remain enforceable between SMEs and investors even if Kapital Boost ceases operations. However, it is important to acknowledge the possibility that investors may need to directly pursue payments from SMEs.</Typography>
                      <Typography className="spaceBottom">Kapital Boost functions solely as an agent for fund transfer between investors and SMEs. We do not retain or have any claim to the money you invest.</Typography>

                      <Typography className="bold">Tax implications</Typography>
                      <Typography className="spaceBig">You have the obligation to manage your own tax matters, which might encompass capital gains and/or income tax. Before making any commitment to provide funding to SMEs, it's crucial that you comprehend and accept the tax implications. We don't provide tax guidance, and if you have uncertainties about your situation, you should independently seek tax advice before participating as an investor. Ensuring the accuracy of your tax return and meeting filing deadlines, as well as paying any due taxes on time, is your responsibility. If you're uncertain about how this investment could impact your tax status, consulting a tax professional before investing is advised.</Typography>
                      <Typography className="spaceBottom">The tax treatment is contingent on your unique circumstances and may be subject to alteration in the future.</Typography>
            </TabPanel>
          </Container>
        </section>
      </div>
    </>
  );

}