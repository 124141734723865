import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";

import Bg from "images/bg-login.png";
import api_public from "services/api_public";
import hp from "services/hp";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: 120,
    paddingBottom: 120,
  },
  paper: {
    padding: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    [theme.breakpoints.up("sm")]: {
      padding: 120,
    },
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 800,
    fontSize: 32,
    color: "#023e8a",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    },
  },
  textField: {
    width: "100%",
  },
  button: {
    width: "100%",
    borderRadius: 50,
    color: "#fff",
    backgroundColor: "#F9AB2E",
    "&:hover": {
      backgroundColor: "#F9AB2E",
    },
  },
}));

const Registration = ({ googleReCaptchaProps, location }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    country: "",
    email: "",
    referral_code: "",
    password: "",
    password_confirm: "",
    how_you_know: "",
  });
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [isSuccessRegistration, setIsSuccessRegistration] = useState(false);
  const [token, setToken] = useState(null);

  function handleChange(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let {
      firstname,
      lastname,
      country,
      email,
      referral_code,
      password,
      password_confirm,
      how_you_know,
      agreed,
    } = form;
    setErrors({
      email_error: false,
      referral_code_error: false,
      country_error: false,
      how_you_know_error: false,
      password_error: false,
      password_confirm_error: false,
    });

    if (country == "") {
      country = "MYANMAR";
    }

    if (how_you_know == "") {
      setErrors({ how_you_know_error: true });
      return;
    }
    if (password.length < 8) {
      setErrors({ password_error: true });
      return;
    }
    if (password != password_confirm) {
      setErrors({ password_confirm_error: true });
      return;
    }
    if (country.toLocaleLowerCase() === "indonesia") {
      setErrors({ country_error: true });
      alert("We regret to inform you that, due to regulatory restrictions, we are currently unable to accept investors from Indonesia.")
      return;
    }
    if (
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/
      )
    ) {
      setErrors({ password_error: true });
      return;
    }
    if (!agreed) {
      setErrors({ agreed_error: true });
      return;
    }
    setIsLoading(true);

    const recaptcha_token = await googleReCaptchaProps.executeRecaptcha(
      "register"
    );

    let register_res = await api_public.post("register", {
      firstname,
      lastname,
      country,
      email,
      referral_code,
      password,
      how_you_know,
      recaptcha_token,
      vendor_id: vendor ? vendor.id : null,
      from_device: 'Website'
    });

    setIsLoading(false);

    let register = register_res.data;
    if (register && register.success) {
      setIsSuccessRegistration(true);
    }

    if (register.error && register.code == "101") {
      setErrors({ email_error: true });
      return;
    }
    if (register.error) {
      alert(register.message);
      return;
    }

    setForm({
      firstname: "",
      lastname: "",
      country: "",
      email: "",
      referral_code: "",
      password: "",
      password_confirm: "",
      how_you_know: "",
    });
  }

  useEffect(() => {
    async function getCountries() {
      setCountries(await hp.get_countries());
    }

    async function getVendor() {
      const params = new URLSearchParams(location.search);
      if (params.get("a")) {
        if (!hp.is_login()) {
          const res = await api_public.get(`partner/${params.get("a")}`);
          if (res && res.data.status) {
            setVendor(res.data.data);
          }
        }
      }
    }

    getCountries();
    getVendor();
  }, [location.search]);

  const handleChangeCaptCha = value => {
    console.log("Captcha value:", value);
    setToken(value);
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper elevation={0} className={classes.paper}>
          <form onSubmit={handleSubmit}>
            {isSuccessRegistration && (
              <Alert variant="filled" severity="success">
                We have sent a verification email to your mailbox. Kindly open
                it and click on link in the email.
              </Alert>
            )}

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  Register
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstname"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={form.firstname}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastname"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      value={form.lastname}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.country_error}
                >
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={form.country}
                    onChange={handleChange}
                    label="Country"
                  >
                    {countries &&
                      countries.map((country, i) => (
                        <MenuItem key={i} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    <MenuItem value="MYANMAR">MYANMAR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={form.email}
                  onChange={handleChange}
                  required
                  error={errors.email_error}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.password_error}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={form.password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  <FormHelperText>
                    Minimum 8 characters with at least 1 uppercase, 1 number and
                    1 symbol
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.password_confirm_error}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    name="password_confirm"
                    type={showConfirmPassword ? "text" : "password"}
                    value={form.password_confirm}
                    onChange={handleChange}
                    error={errors.password_confirm_error}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="referral_code"
                  label="Referral Code (optional)"
                  variant="outlined"
                  fullWidth
                  value={form.referral_code}
                  onChange={(e) => {
                    if (e.target.value.length <= 4) {
                      handleChange(e);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  error={errors.how_you_know_error}
                >
                  <InputLabel>How did you know us?</InputLabel>
                  <Select
                    name="how_you_know"
                    value={form.how_you_know}
                    label="How did you know us?"
                    onChange={handleChange}
                  >
                    <MenuItem value="Search Engine">Search Engine</MenuItem>
                    <MenuItem value="Ads">Ads</MenuItem>
                    <MenuItem value="Social Media">Social Media</MenuItem>
                    <MenuItem value="Friend/Family">Friend/Family</MenuItem>
                    <MenuItem value="Community Event">Community Event</MenuItem>
                    <MenuItem value="News/Blog/Magazine">
                      News/Blog/Magazine
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  error={errors.agreed_error}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={form.agreed}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              agreed: !form.agreed,
                            })
                          }
                          value="agreed"
                          id="register-agree-checkbox"
                        />
                      }
                      label={
                        <label htmlFor="register-agree-checkbox">
                          * I agree to the{" "}
                          <a target="_blank" href="/legal#term">
                            Terms of Use
                          </a>{" "}
                          and{" "}
                          <a target="_blank" href="/legal#privacy-policy">
                            Privacy Policy
                          </a>
                        </label>
                      }
                      margin="normal"
                    />
                  </FormGroup>
                  {errors.agreed_error && (
                    <FormHelperText>
                      Please agree to the Terms of Use and Privacy Policy
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* <Grid item xs={12}>
                <label style={{display: 'block', marginBottom: '10px'}}>ReCaptcha</label>

                <ReCAPTCHA
                  sitekey={"6LcEHsQlAAAAAFdiMqK9w7oLpunmXmi9TQT1XJz7"}
                  onChange={handleChangeCaptCha}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableElevation
                  className={classes.button}
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Register"
                  ) : (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default withGoogleReCaptcha(Registration);
