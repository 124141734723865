import React, { Component, useState } from "react";

import './styles/global.scss';

import BannerImageWeb from 'images/faq/banner.jpg';
import BannerImageMobile from 'images/faq/banner-m.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {FAQs} from '../utils/faq'

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
  } from "@material-ui/core";
import { Helmet } from "react-helmet";

  export default function Faq() {
    const [value, setValue] = React.useState("sme");
    const [smeTab, setSmeTab] = React.useState("sme");
    const [tabShown, setTabShown] = React.useState("");

    const handleChange = (newValue) => {
      setValue(newValue);
      setTabShown("");
    };

    const handleChangeSmeTab = (newValue) => {
      setSmeTab(newValue);
      setTabShown("");
    };

    const handleChangeTabShow = (newValue) => {
      if (tabShown === newValue) {
        setTabShown("");
      } else {
        setTabShown(newValue);
      }
    };

    const FaqContent = ({item, faqKey, idx}) => (
      <Paper 
        elevation={3} 
        className={`tabitem ${tabShown === faqKey + "-" + idx ? "show" : ""}`} 
        onClick={() => handleChangeTabShow(faqKey + "-" + idx)}>
        <div className={`tab-content`}>
          <div className="header">
            <span className="head-title">{item.q}</span>
            <span className="chev-icon">
              <ChevronRightIcon style={{ fontSize: 40 }} />
            </span>
          </div>
          <div className="content" dangerouslySetInnerHTML={{__html: item.a}}></div>
        </div>
      </Paper>
    )

    return (
      <>
        <Helmet>
          <title>Kapital Boost FAQ | Frequently Asked Questions</title>
          <meta name="description" content="Find answers to frequently asked questions about Kapital Boost, our Islamic P2P financing platform, and how we help SMEs." />
        </Helmet>
        
        <div data-page="faq">
            <div className="header">
                <img className="header-background" alt="header for about us" src={BannerImageMobile} srcSet={`${BannerImageMobile} 768w, ${BannerImageWeb} 1280w`} loading="lazy" />

                <div className="header-body">
                    <Container>
                        <h1>Frequently Asked <br/>Questions</h1>
                    </Container>
                </div>
            </div>

            <div className="content-body">
              <Container>
                <Grid container
                  direction="row"
                  alignItems="center"
                  className="mui-justify-center tab-space">
                    <Grid item>
                      <Paper elevation={3} className={`tab-button-item ${value === "sme" ? "active" : ""}`} onClick={(e) => handleChange("sme")}>
                        <svg width="110" height="111" viewBox="0 0 110 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M95.4297 21.5156H14.1797C12.4512 21.5156 11.0547 22.9121 11.0547 24.6406V87.1406C11.0547 88.8691 12.4512 90.2656 14.1797 90.2656H95.4297C97.1582 90.2656 98.5547 88.8691 98.5547 87.1406V24.6406C98.5547 22.9121 97.1582 21.5156 95.4297 21.5156ZM86.416 40.5L57.4121 69.5234C57.2653 69.6688 57.067 69.7504 56.8604 69.7504C56.6537 69.7504 56.4554 69.6688 56.3086 69.5234L45.127 58.3418L28.2812 75.1973C28.1344 75.3427 27.9361 75.4242 27.7295 75.4242C27.5229 75.4242 27.3246 75.3427 27.1777 75.1973L23.584 71.6035C23.4386 71.4567 23.357 71.2584 23.357 71.0518C23.357 70.8451 23.4386 70.6468 23.584 70.5L44.5703 49.5039C44.873 49.2012 45.3711 49.2012 45.6738 49.5039L56.8555 60.6758L81.709 35.8125C82.0117 35.5098 82.5098 35.5098 82.8125 35.8125L86.4062 39.4063C86.7188 39.6992 86.7188 40.1973 86.416 40.5Z" fill={value === "sme" ? "#0D44AC" : "#BDBDBD"}/>
                        </svg>

                        <span className="tab-title">SME crowdfunding</span>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper elevation={3} className={`tab-button-item ${value === "donation" ? "active" : ""}`} onClick={(e) => handleChange("donation")}>
                      <svg width="110" height="111" viewBox="0 0 110 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M78.8584 59.75L63.3334 72.1667H42.5V68H59.4375C59.8144 67.9998 60.1841 67.8974 60.5074 67.7037C60.8306 67.51 61.0953 67.2322 61.2731 66.9C61.451 66.5678 61.5354 66.1935 61.5174 65.8171C61.4994 65.4407 61.3796 65.0762 61.1709 64.7625L57.4709 59.2125C56.7118 58.07 55.6819 57.1332 54.4729 56.4854C53.2639 55.8376 51.9133 55.4991 50.5417 55.5H17.5C16.395 55.5 15.3352 55.939 14.5538 56.7204C13.7724 57.5018 13.3334 58.5616 13.3334 59.6667V84.6667C13.3334 86.8768 14.2113 88.9964 15.7742 90.5592C17.337 92.122 19.4566 93 21.6667 93H61.8292C63.6109 93.0004 65.3721 92.6199 66.9948 91.8841C68.6174 91.1482 70.064 90.0739 71.2375 88.7333L96.6667 59.6667L90.6167 57.65C88.6373 56.9902 86.5242 56.8367 84.4703 57.2035C82.4163 57.5704 80.487 58.4458 78.8584 59.75V59.75ZM85.2417 36.2917C87.1209 34.3542 88.2834 31.675 88.2834 28.7167C88.2834 25.7583 87.1209 23.0792 85.2417 21.1417C84.2914 20.1499 83.1505 19.3604 81.8874 18.8205C80.6244 18.2807 79.2653 18.0016 77.8917 18C77.8917 18 72.7084 17.9875 67.5 23.3583C62.2917 17.9875 57.1084 18 57.1084 18C55.7352 18.0017 54.3765 18.2805 53.1136 18.8196C51.8507 19.3587 50.7095 20.1471 49.7584 21.1375C47.8792 23.0792 46.7167 25.7542 46.7167 28.7125C46.7167 31.6708 47.8792 34.3542 49.7584 36.2875L67.5 55.5L85.2417 36.2917Z" fill={value === "donation" ? "#0D44AC" : "#BDBDBD"}/>
                      </svg>

                        <span className="tab-title">Donation</span>
                      </Paper>
                    </Grid>
                </Grid>
              </Container>

              <div role="tabpanel" hidden={value !== "sme"}>
                <Container>
                    <Grid container
                      direction="row"
                      alignItems="center"
                      className="mui-justify-between">
                        <Grid>
                          <a 
                            href="javascript::void(0);" 
                            className={`sme-tab-button ${smeTab === "sme" ? "active" : ""}`} 
                            onClick={() => handleChangeSmeTab("sme")}
                            >
                              For SMEs
                          </a>
                        </Grid>
                        <Grid>
                          <a 
                            href="javascript::void(0);" 
                            className={`sme-tab-button ${smeTab === "member" ? "active" : ""}`} 
                            onClick={() => handleChangeSmeTab("member")}
                            >
                              For financiers (Members)
                          </a>
                        </Grid>
                    </Grid>
                    
                    <div className="tabpanel" role="tabpanel" hidden={smeTab !== "sme"}>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={6} item>
                          <Grid container spacing={3}>
                            {FAQs.sme.sme.map((item, idx) => {
                              if (idx % 2 === 0) {
                                return (
                                  <Grid xs={12} sm={12} md={12} item key={idx}>
                                    <Paper 
                                      elevation={3} 
                                      className={`tabitem ${tabShown === "member-" + idx ? "show" : ""}`} 
                                      onClick={() => handleChangeTabShow("member-" + idx)}>
                                      <div className={`tab-content`}>
                                        <div className="header">
                                          <span className="head-title">{item.q}</span>
                                          <span className="chev-icon">
                                            <ChevronRightIcon style={{ fontSize: 40 }} />
                                          </span>
                                        </div>
                                        <div className={`content ${item.c === true ? 'title-small':''}`} dangerouslySetInnerHTML={{__html: item.a}}></div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} item>
                          <Grid container spacing={3}>
                            {FAQs.sme.sme.map((item, idx) => {
                              if (idx % 2 === 1) {
                                return (
                                  <Grid xs={12} sm={12} md={12} item key={idx}>
                                    <Paper 
                                      elevation={3} 
                                      className={`tabitem ${tabShown === "member-" + idx ? "show" : ""}`} 
                                      onClick={() => handleChangeTabShow("member-" + idx)}>
                                      <div className={`tab-content`}>
                                        <div className="header">
                                          <span className="head-title">{item.q}</span>
                                          <span className="chev-icon">
                                            <ChevronRightIcon style={{ fontSize: 40 }} />
                                          </span>
                                        </div>
                                        <div className={`content ${item.c === true ? 'title-small':''}`} dangerouslySetInnerHTML={{__html: item.a}}></div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="tabpanel" role="tabpanel" hidden={smeTab !== "member"}>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={6} item>
                          <Grid container spacing={3}>
                            {FAQs.sme.member.map((item, idx) => {
                              if (idx % 2 === 0) {
                                return (
                                  <Grid xs={12} sm={12} md={12} item key={idx}>
                                    <Paper 
                                      elevation={3} 
                                      className={`tabitem ${tabShown === "member-" + idx ? "show" : ""}`} 
                                      onClick={() => handleChangeTabShow("member-" + idx)}>
                                      <div className={`tab-content`}>
                                        <div className="header">
                                          <span className="head-title">{item.q}</span>
                                          <span className="chev-icon">
                                            <ChevronRightIcon style={{ fontSize: 40 }} />
                                          </span>
                                        </div>
                                        <div className={`content ${item.c === true ? 'title-small':''}`} dangerouslySetInnerHTML={{__html: item.a}}></div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} item>
                          <Grid container spacing={3}>
                            {FAQs.sme.member.map((item, idx) => {
                              if (idx % 2 === 1) {
                                return (
                                  <Grid xs={12} sm={12} md={12} item key={idx}>
                                    <Paper 
                                      elevation={3} 
                                      className={`tabitem ${tabShown === "member-" + idx ? "show" : ""}`} 
                                      onClick={() => handleChangeTabShow("member-" + idx)}>
                                      <div className={`tab-content`}>
                                        <div className="header">
                                          <span className="head-title">{item.q}</span>
                                          <span className="chev-icon">
                                            <ChevronRightIcon style={{ fontSize: 40 }} />
                                          </span>
                                        </div>
                                        <div className="content" dangerouslySetInnerHTML={{__html: item.a}}></div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                </Container>
              </div>

              <div className="tabpanel" role="tabpanel" hidden={value !== "donation"}>
                <Container>
                  <Grid container spacing={3}>
                    <Grid xs={12} sm={12} md={6} item>
                      <Grid container spacing={3}>
                      {FAQs.donation.map((item, idx) => {
                          if (idx % 2 === 0) {
                            return (
                              <Grid xs={12} sm={12} md={12} item key={idx}>
                                <Paper 
                                  elevation={3} 
                                  className={`tabitem ${tabShown === "donation-" + idx ? "show" : ""}`} 
                                  onClick={() => handleChangeTabShow("donation-" + idx)}>
                                  <div className={`tab-content`}>
                                    <div className="header">
                                      <span className="head-title">{item.q}</span>
                                      <span className="chev-icon">
                                        <ChevronRightIcon style={{ fontSize: 40 }} />
                                      </span>
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: item.a}}></div>
                                  </div>
                                </Paper>
                              </Grid>
                            )
                          }
                        })}
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} item>
                      <Grid container spacing={3}>
                        {FAQs.donation.map((item, idx) => {
                          if (idx % 2 === 1) {
                            return (
                              <Grid xs={12} sm={12} md={12} item key={idx}>
                                <Paper 
                                  elevation={3} 
                                  className={`tabitem ${tabShown === "donation-" + idx ? "show" : ""}`} 
                                  onClick={() => handleChangeTabShow("donation-" + idx)}>
                                  <div className={`tab-content`}>
                                    <div className="header">
                                      <span className="head-title">{item.q}</span>
                                      <span className="chev-icon">
                                        <ChevronRightIcon style={{ fontSize: 40 }} />
                                      </span>
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: item.a}}></div>
                                  </div>
                                </Paper>
                              </Grid>
                            )
                          }
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
        </div>
      </>
    );
}