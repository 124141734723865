import React, { createContext, useContext, useState } from "react";

import api from '../services/api'

const LogoutDialogContext = createContext(null);

function LogoutDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  async function onLogout() {
    const res = await api.post('logout')
    const {data} = res
    localStorage.clear();
    window.location.reload();
  }

  return (
    <LogoutDialogContext.Provider
      value={{
        isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        onLogout,
      }}
    >
      {children}
    </LogoutDialogContext.Provider>
  );
}

function useLogoutDialog() {
  return useContext(LogoutDialogContext);
}

export { LogoutDialogContext, LogoutDialogProvider, useLogoutDialog };
