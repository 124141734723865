import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  LinearProgress,
  Paper,
  Typography,
  Button,
  Grid,
  Select,
} from "@material-ui/core";
import { AccountCircle, Close, LocationOn, Business } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";

import {
  isFuture,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import "./styles/campaign.css";

function PillButton({
  text,
  disabled = false,
  loading = false,
  onClick = () => {},
  backgroundColor,
}) {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      class={backgroundColor}
      onClick={() => onClick()}
      style={{fontWeight: 400}}
    >
      {text}
    </Button>
  );
}
export default function CampaignDetail({
  campaign,
  onOpenLoginDialog = (e) => {},
}) {
  const total_invested = campaign.investments_count ? campaign.investments_count : 0
  const dashboardUrl = process.env.REACT_APP_DASHBOARD_SITE

  function ProgressBar({ type, subtype, value }) {
    return (
      <LinearProgress
        className={
          type == "donation"
            ? "progress spaceBottom sunshineBg"
            : subtype == "INVOICE  FINANCING"
            ? "progress spaceBottom darkblueBg"
            : "progress spaceBottom"
        }
        variant="determinate"
        value={value}
      />
    );
  }
  function riskClassName(risk) {
    if (risk.includes("A")) return "risk-a";
    if (risk.includes("B")) return "risk-b";
    if (risk.includes("C")) return "risk-c";
    return "";
  }
  function LabeledInfo({ label, info, risk }) {
    return (
      <div className="flex flex-column">
        <Typography class="labeled-info__label text-align-center">
          {label}
        </Typography>
        {risk && (
          <Typography
            className={
              "bold labeled-info__risk p-top s16 text-align-center " +
              riskClassName(risk)
            }
          >
            {risk}
          </Typography>
        )}
        {info && (
          <Typography className="bold p-top s16 text-align-center">
            {info}
          </Typography>
        )}
      </div>
    );
  }

  function disability() {
    if (campaign.type === "donation") {
      if (!isFuture(new Date(campaign?.expiry_datetime.replace(/-/g, "/")))) {
        return true
      }
    } else if (
      campaign.type === 'sme' &&
      !isFuture(new Date(campaign?.expiry_datetime.replace(/-/g, "/"))) ||
      Math.min(
        (parseInt(total_invested) / campaign.total_invest_amount) *
          100,
        100
      ) >= 100
    ) {
      return true;
    }
    return false;
  }

  return (
    <Paper elevation={3} className="campaign-card">
      <div
        className={`campaign_image_container ${
          campaign?.type !== "donation" && "campaign_image_container_sme"
        }`}
      >
        <img src={campaign?.cover_image} alt="" />

        {campaign?.type !== "donation" && (
          <div className="campaign-acronim-type">
            <div>{campaign?.acronim}</div>
            <div>{campaign?.subtype}</div>
          </div>
        )}
      </div>

      <div className="campaign-content">
        {campaign.type !== "donation" && (
          <>
            <div className="campaign__bar sme_bar">
              <Typography className="bold p-bottom s16">
                {campaign.is_prelaunch ? 0 : Math.floor(
                  (parseInt(total_invested) /
                    campaign.total_invest_amount) *
                    100,
                  100
                ).toFixed(0)}
                % of target funding
              </Typography>

              <ProgressBar
                type={campaign?.type}
                subtype={campaign?.subtype}
                value={campaign.is_prelaunch ? 0 : Math.min(
                  (parseInt(total_invested) /
                    campaign.total_invest_amount) *
                    100,
                  100
                )}
              />
              <div className="labels">
                <Typography className="bold b-right p-top s16">
                  {campaign.is_prelaunch ? `S$ 0` : `S$ ${hp.format_money(total_invested)}`}
                </Typography>
                <Typography className=" b-right p-top s16 f-12 flex align-items-center">
                  {hp.get_day_left(campaign)[0]}{" "}
                  {hp.get_day_left(campaign)[1].toLowerCase()} left
                </Typography>
              </div>
            </div>
            <div className="campaign__info">
              <Grid container spacing={2}>
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <LocationOn
                    className="campaign__info-icon"
                    style={{ marginRight: 10 }}
                  />{" "}
                  <Typography
                    className="campaign__info-info"
                    style={{ fontSize: 12 }}
                  >
                    {campaign.country}
                  </Typography>
                </Grid>
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <Business
                    className="campaign__info-icon "
                    style={{ marginRight: 10 }}
                  />{" "}
                  <Typography
                    className="campaign__info-info truncate-1"
                    style={{ fontSize: 12 }}
                  >
                    {campaign.industry}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="info_grid">
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <LabeledInfo
                    label="Target"
                    info={`S$ ${hp.format_money(campaign.total_invest_amount)}`}
                  />
                </Grid>
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <LabeledInfo label="Tenor" info={campaign?.tenor} />
                </Grid>
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <LabeledInfo label="Return" info={`${campaign?.return}%`} />
                </Grid>
                <Grid
                  className="flex justify-content-center align-items-center"
                  xs={6}
                >
                  <LabeledInfo label="Risk Rating" risk={campaign?.risk} />
                </Grid>
              </Grid>
            </div>
          </>
        )}
        {campaign?.type === "donation" && (
          <>
            <div className="conation-container">
              <Typography variant="h5" className="bold truncate-1">
                {campaign?.acronim}
              </Typography>
              <Typography variant="p" className="truncate-2">
                {campaign?.snippet}
              </Typography>
            </div>

            <div className="campaign__bar">
              <div className="labels">
                <Typography className="bold b-right p-top s16">
                  Funded S$ {hp.format_money(campaign?.investments_count)}
                </Typography>
                <Typography className="bold b-right p-top s16">
                  Target S$ {hp.format_money(campaign?.total_invest_amount)}
                </Typography>
              </div>
              <ProgressBar
                type={campaign?.type}
                subtype={campaign?.subtype}
                value={Math.min(
                  (parseInt(total_invested) /
                    campaign.total_invest_amount) *
                    100,
                  100
                )}
              />
              <div className="labels">
                <Typography className="gray-3 p-top f-12 flex align-items-center">
                  {hp.get_day_left(campaign)[0]}{" "}
                  {hp.get_day_left(campaign)[1].toLowerCase()} left
                </Typography>
                <Typography className=" flex align-items-center  p-top f-12">
                  <Typography
                    className="campaign__info-info gray-3"
                    style={{ fontSize: 10, alignSelf: "flex-end" }}
                  >
                    {campaign.country}
                  </Typography>
                  <LocationOn
                    className="campaign__info-icon gray-3"
                    style={{ fontSize: 16 }}
                  />{" "}
                </Typography>
              </div>
            </div>
          </>
        )}
        <div class="flex justify-content-center button-tabs-campaign">
          {hp.is_login() ? (
            // <a href={`${dashboardUrl}/dashboard/campaigns?slug=${campaign?.slug}`} target={'_blank'}>
            <Link to={`/campaign/${campaign?.slug}`} target={'_blank'}>
              <PillButton
                disabled={disability()}
                text={
                  campaign?.type === "donation" ? "Donate Now" : "Invest Now"
                }
                backgroundColor={
                  campaign?.type === "donation"
                    ? "soulstine-blueBg"
                    : "sunshineBg"
                }
              />
            </Link>
          ) : (
            <Link to="#" onClick={(e) => onOpenLoginDialog(e)}>
              <PillButton
                disabled={disability()}
                text={
                  campaign?.type === "donation" ? "Donate Now" : "Invest Now"
                }
                backgroundColor={
                  campaign?.type === "donation"
                    ? "soulstine-blueBg"
                    : "sunshineBg"
                }
              />
            </Link>
          )}
          {/* <Link to={`/campaign/${campaign?.slug}`}>
                  <PillButton disabled={disability()} text={campaign?.type === "donation" ? "Donate Now" : "Invest Now"} backgroundColor={campaign?.type === "donation" ? "soulstine-blueBg" : "sunshineBg" } />
                </Link>
               */}
        </div>
      </div>
    </Paper>
  );
}
