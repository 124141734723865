import React, { Component, Fragment } from "react";

import api from "services/api";

class Paypal extends Component {
    
    state = {
        amount: this.props.amount,
        campaign: this.props.campaign,
        investment_id: this.props.investment_id
    }

    constructor(props) {
        super(props);

        this.paypal = React.createRef();
    }

    componentDidMount() {        
        const amount = this.state.amount;
        const acronim = this.state.campaign.acronim;
        const investment_id = this.state.investment_id;
        const updateInvestmen = (investment) => this.props.updateInvestmen(investment);

        window.paypal.Buttons({
            style: {
                shape: 'pill',
                color: 'silver',
                layout: 'vertical',
                label: 'pay',    
            },
            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        description: `Donation for ${acronim}`,
                        amount: {
                            value: amount
                        }
                    }], 
                    application_context: {
                        shipping_preference: 'NO_SHIPPING'
                    }
                });
            },

            onApprove: function(data, actions) {
                return actions.order.capture().then(function(orderData) {
                    const transaction = orderData.purchase_units[0].payments.captures[0];
                    window.scrollTo({top: 0, behavior: 'smooth'});

                    // set investment null to show the loading page
                    updateInvestmen(null)

                    // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                    // alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
                    
                    const params = new FormData;
                    params.append('transaction_id', transaction.id);
                    params.append('transaction_status', transaction.status);
                    params.append('investment_id', investment_id);

                    api.post("payment-paypal", params).then(res => {
                        const {data} = res;

                        updateInvestmen(data.investment);
                    })
                });
            }
        }).render(this.paypal.current)
    }

    

    render() {
        return (
            <>
                <div ref={this.paypal}></div>
            </>
        )
    }
}

export default Paypal