import React, { useState } from 'react'

import { Button, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Paper, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core';
import { MailOutlineOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import api from "services/api";
import { Alert } from '@material-ui/lab';

const ChangePW = () => {
    const [time, setTime] = useState(0);
    const [showCpw, setShowCpw] = useState(false);
    const [showNpw, setShowNpw] = useState(false);
    const [showNCpw, setShowNCpw] = useState(false);
    const [snack, setSnack] = useState({
        open: false,
        severity: 'success',
        message: null
    })
    const [items, setItems] = useState({
        password: null,
        new_password: null,
        password_confirmation: null,
        code: null
    })
    const [errors, setErrors] = useState({
        password: null,
        new_password: null,
        password_confirmation: null,
        code: null
    })
    const [loading, setLoading] = useState(false)

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setSnack({ open: false, message: null });
    };

    const getCode = async () => {
        setTime(120)
        await api.get("user/request-code").then(res => {
            setSnack({severity: 'success', open: true, message: res.data.message})

            let timer = setInterval(() => {
                setTime((time) => {
                    if (time === 0) {
                        clearInterval(timer);
                        return 0;
                    } else return time - 1;
                });
            }, 1000);
        }).catch(error => {
            const res = error.response;
            setSnack({severity: 'error', open: true, message: res.data.message})
        })
    }

    const onChange = (e, f) => {
        e.preventDefault()
        // console.log(f, e.target.value);
        
        let changedItem = items;
        changedItem[f] = e.target.value;

        setItems({ ...items, changedItem });
        setErrors({
            ...errors,
            [f]: null,
        })
    };

    const onSave = () => {
        // run validation        
        if (! checkValidity()) {   
            setLoading(true) 
            api.post("user/reset-password", items).then(res => {
                // console.log(res);
                setSnack({severity: 'success', open: true, message: res.data.message})
                // Reset fields
                window.location.reload();
            }).catch((error) => {
                const res = error.response;
                // console.log(res);
                setSnack({severity: 'error', open: true, message: res.data.message})
            }).finally(() => setLoading(false))
        }

        return false;
    }

    const checkValidity = () => {
        if (items.password === '' || items.password === null) {
            return true
        }

        if (items.new_password === '' || items.new_password === null) {
            return true
        }

        if (
            !items.new_password.match(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
            )
          ) {
            setErrors({...errors, new_password: 'Password should be 8 to 16 characters with at least 1 uppercase, 1 number and 1 symbol'})
            return true;
        }

        if (items.password_confirmation === '' || items.password_confirmation === null) {
            return true
        } else {
            if (items.new_password !== items.password_confirmation) {
                setErrors({...errors, password_confirmation: 'The password confirmation does not match.'})
                return true
            }
        }

        if (items.code === '' || items.code === null) {
            return true
        }

        return false
    }

    const changeTypeCurrentPassword = () => {
        setShowCpw(!showCpw)
    }

    const changeTypeNewPassword = () => {
        setShowNpw(!showNpw)
    }

    const changeTypeNewPasswordConfirm = () => {
        setShowNCpw(!showNCpw)
    }

    return (
        <Paper>
            <div className='padding'>
                <Typography component={'h5'} className="bold spaceBottom">
                    Change Password
                </Typography>

                    <Grid item xs={12} md={6}>
                        <div className="padding">
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="standard-adornment-password">Current Password</InputLabel>
                                <Input
                                    name='password'
                                    margin="normal"
                                    label={'Current Password'}
                                    fullWidth
                                    required
                                    value={items.password}
                                    onChange={(e) => onChange(e, 'password')}
                                    type={showCpw ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={changeTypeCurrentPassword}
                                            onMouseDown={changeTypeCurrentPassword}
                                        >
                                            {showCpw ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl style={{width: '100%', margin: '30px 0 0'}}>
                                <InputLabel htmlFor="standard-adornment-password">New Password*</InputLabel>
                                <Input
                                    name='password'
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={errors.new_password != null}
                                    helperText={'Password should be 8 to 16 characters with at least 1 uppercase, 1 number and 1 symbol'}
                                    value={items.new_password}
                                    onChange={(e) => onChange(e, 'new_password')}
                                    type={showNpw ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={changeTypeNewPassword}
                                            onMouseDown={changeTypeNewPassword}
                                        >
                                            {showNpw ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Typography variant="caption" display="block" gutterBottom>Password should be 8 to 16 characters with at least 1 uppercase, 1 number and 1 symbol</Typography>
                            </FormControl>
                            <FormControl style={{width: '100%', marginTop: '15px'}}>
                                <InputLabel htmlFor="standard-adornment-password">Password Confirmation</InputLabel>
                                <Input
                                    name='password'
                                    margin="normal"
                                    label={'Password Confirmation'}
                                    fullWidth
                                    required
                                    value={items.password_confirmation}
                                    onChange={(e) => onChange(e, 'password_confirmation')}
                                    type={showNCpw ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={changeTypeNewPasswordConfirm}
                                            onMouseDown={changeTypeNewPasswordConfirm}
                                        >
                                            {showNCpw ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            {/* <TextField
                                margin="normal"
                                label={'Password Confirmation'}
                                type="password"
                                fullWidth
                                required
                                error={errors.password_confirmation != null}
                                helperText={errors.password_confirmation}
                                value={items.password_confirmation}
                                onChange={(e) => onChange(e, 'password_confirmation')}
                            /> */}

                            <Paper component={'div'} style={{padding: '0', display: 'flex', alignItems: 'center', marginTop: 20, background: 'transparent', border: 'none', boxShadow: 'none', borderRadius: '0'}} >
                                <TextField
                                    margin="normal"
                                    label={'OTP Code - Get code by click the mail button'}
                                    style={{marginLeft: 0, flex: 1}}
                                    type="text"
                                    fullWidth
                                    required
                                    value={items.code}
                                    onChange={(e) => onChange(e, 'code')}
                                />
                                <Divider style={{height: 28, margin: 4,}} orientation="vertical" />
                                <Tooltip title="Get code" aria-label="add">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<MailOutlineOutlined />}
                                    onClick={getCode}
                                    disabled={time !== 0}
                                >
                                    {time === 0 ? "Get Code" : (
                                        <>
                                            {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)}
                                        </>
                                    )}
                                </Button>
                                </Tooltip>
                            </Paper>
                        </div>

                        <div className='padding'>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ alignSelf: "flex-end" }}
                                disabled={!items.code || loading}
                                onClick={onSave}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
            </div>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={snack.open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={snack.severity}>
                    {snack.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

export default ChangePW;