import React, { Component, useState } from "react";
import api from "services/api";

import { Grid, FormControl, Select, MenuItem, TextField, InputLabel, FormHelperText, Typography, FormGroup, FormControlLabel, Checkbox, Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const fields = [
  {name: "company_name", label: "Full company name", type: "text", required: true, isSelect: false},
  {name: "company_registration_number", label: "Company registration number", type: "text", required: true, isSelect: false},
  {name: "industry", label: "Industry", type: "text", required: true, isSelect: false},
  // {name: "year_established", label: "Year established", type: "number", required: true, isSelect: false},
  // {money: true, 
  //   currency: {name: "currency", options: ["IDR", "MYR", "SGD"], required: true}, 
  //   annual_revenue: {name: "annual_revenue", label: "Annual revenue", type: "number", required: true}
  // },
  // {name: "funding_amount", label: "Funding amount", type: "number", required: true, isSelect: false},
  {name: "annual_revenue", label: "Annual revenue", type: "select", required: true, isSelect: true, options: ["SGD 100k-299k", "SGD 300k-599k", "SGD 600k-1mn", "above SGD 1 mn"]},
  {name: "name", label: "Your name", type: "name", required: true, isSelect: false},
  {name: "email", label: "Email address", type: "email", required: true, isSelect: false},
  {name: "phone_no", label: "Contact number", type: "text", required: true, isSelect: false},
  // {name: "funding_period", label: "Funding period", type: "select", required: true, isSelect: true, options: ["1 months", "2 months", "3 months", "4 months", "5 months", "6 months"]},
  {name: "financing_solution", label: "Preferred financing solution", type: "select", required: true, isSelect: true, options: ["Invoice Financing", "Asset Purchase Financing"]},
  // {name: "how_you_know", label: "How did you know us?", type: "select", required: true, isSelect: true, options: ["Search Engine", "Ads", "Social Media", "Friend/Family", "Community Event", "News/Blog/Magazine", "Other"]},
]

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GetFundedForm() {
  const [dialogeOpen, setDialogOpen] = useState(false);
  const [funded, setFunded] = useState({});
  const [checks, setChecks] = useState({});
  const [error, setError] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [message, setMessage] = useState({variant: "", text: "", isShow: false});

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage({variant: "", text: "", isShow: false});
  };

  const onChange = (event, key) => {
    const currFunded = funded
    currFunded[key] = event.target.value
    setFunded(currFunded)
  }

  const onChangeCheckbox = (f) => {
    const currChecks = checks
    currChecks[f] = !checks[f];
    setChecks(currChecks);
    setError({})
  };

  const handleDaClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDaClose = () => {
    setDialogOpen(false);
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    const currFunded = funded
    setError({})

    if (! checks.privacy_check) {
      setError({privacy_error: true})
    }
    
    if (! checks.information_check) {
      setError({information_error: true})
    }

    setButtonLoading(true)

    const recaptcha_token = await executeRecaptcha("getfunded");
    currFunded['recaptcha_token'] = recaptcha_token

    const res = await api.post("get-funded", currFunded)
    const { data } = res;
    
    setButtonLoading(false)
    
    if (data.error) {
      setMessage({text: data.message, variant: "error", isShow: true})
    } else {
      // setMessage({text: "Your application has been sent", variant: "success", isShow: true})
      handleDaClickOpen()
      
      setFunded({})
      setChecks({})
    }
  }

  // components
  const MoneyField = ({field}) => (
    <Grid container alignItems="center">
      <Grid xs={6} md={4} item>
        <FormControl
          required={field.currency.required}
          style={{ width: "95%" }}
          margin="normal"
          variant="outlined"
        >
          <InputLabel>Currency</InputLabel>
          <Select
            value={funded[field.currency.name]}
            onChange={(e) => onChange(e, field.currency.name)}
          >
            {field.currency.options.map((opt, idx) => (
              <MenuItem value={opt} key={idx}>{opt}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6} md={8} item>
        <TextField
          margin="normal"
          value={funded[field.annual_revenue.name]}
          onChange={(e) => onChange(e, field.annual_revenue.name)}
          label={field.annual_revenue.label}
          type={field.annual_revenue.type}
          required={field.annual_revenue.required}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  )

  const SelectField = ({field}) => (
    <FormControl required fullWidth margin="normal" variant="outlined">
      <InputLabel>{field.label}</InputLabel>
      <Select
        value={funded[field.name]}
        onChange={(e) => onChange(e, field.name)}
      >
        {field.options.map((opt, idx) => (
          <MenuItem value={opt} key={idx}>{opt}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const InputnField = ({field, idx}) => (
    <TextField
      margin="normal"
      value={funded[field.name]}
      onChange={(e) => onChange(e, field.name)}
      label={field.label}
      type={field.type}
      variant="outlined"
      required={field.required}
      fullWidth
    ></TextField>
  )

  const CheckFields = () => (
    <center>
      <Typography className="space">* Required</Typography>

      <FormControl error={error.information_error}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checks.information_check}
                onChange={() =>
                  onChangeCheckbox("information_check")
                }
                value="information_check"
              />
            }
            label="*I confirm the information provided are correct and accurate."
            className="space"
          />
        </FormGroup>
        {error.information_error && (
          <FormHelperText>Please check this</FormHelperText>
        )}
      </FormControl>

      <FormControl error={error.privacy_error}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checks.privacy_check}
                onChange={() =>
                  onChangeCheckbox("privacy_check")
                }
                value="privacy_check"
                id="privacy-check"
              />
            }
            label={
              <label htmlFor="privacy-check">
                *I have read and agreed to the{" "}
                <a target="_blank" href="/legal#term">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a target="_blank" href="/legal#privacy">
                  Privacy Policy
                </a>
              </label>
            }
            className="space"
          />
        </FormGroup>
        {error.privacy_error && (
          <FormHelperText>Please check this</FormHelperText>
        )}
      </FormControl>
    </center>
  )

  return (
    <div className="form-getfunded">
      <form onSubmit={onSubmit}>

        {fields.map((field, idx) => {
          if (field.isSelect) {
            return (
              <div key={idx}>
                <SelectField field={field} />
              </div>
            )
          } if (field.money) {
            return (
              <div key={idx}>
                <MoneyField field={field} />
              </div>
            )
          } else {
            return (
              <div key={idx}>
                <InputnField field={field} idx={idx} />
              </div>
            )
          }
        })} 

        <CheckFields />

        <div className="mt-2 text-center">
          <button type="submit" className="btn-kb btn-orange" disabled={buttonLoading}>{buttonLoading ? "Loading..." : "Submit"}</button>
        </div>
        {/* <Button
          margin="normal"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          className="spaceBig"
          disabled={buttonLoading}
        >
          {buttonLoading ? "Loading..." : "Submit"}
        </Button> */}

        <Snackbar open={message.isShow} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.variant}>
            {message.text}
          </Alert>
        </Snackbar>

      </form>

      <Dialog
        open={dialogeOpen}
        onClose={handleDaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              <h3>Thank you for your application for funding via Kapital Boost. <br />Our team shall get back to you within the next 24 hours on this.</h3>
            </center>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDaClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}