import React, { useEffect } from "react";
import BigCarousel from "components/BigCarousel";
import { Box, makeStyles } from "@material-ui/core";
import Achievement from "components/Achievement";
import WhatWeDo from "components/WhatWeDo";
import Excellence from "components/Excellence";
import OurCampaigns from "components/OurCampaigns";
import Recognitions from "components/Recognitions";
import FeaturedOn from "components/FeaturedOn";
import Subscription from "components/Subscription";
import Testimonials from "components/Testimonials";
import hp from "services/hp";
import api from 'services/api'
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  achievement: {
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      bottom: 0,
      left: 0,
      transform: "none",
    },
    position: "absolute",
    bottom: -47,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  whatWeDo: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    marginTop: 47,
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <div id="home">
      <Helmet>
        <title>Kapital Boost | Empowering SMEs with Islamic P2P Financing</title>
        <meta name="description" content="Discover Kapital Boost, the leading Islamic P2P financing platform that helps SMEs grow. Learn about our innovative financing solutions today." />
      </Helmet>
      <Box position="relative">
        <BigCarousel />

        <div className={classes.achievement}>
          <Achievement />
        </div>
      </Box>

      <div className={classes.whatWeDo}>
        <WhatWeDo />
      </div>

      <Excellence />

      <OurCampaigns />

      <Testimonials />

      <Recognitions />
      
      <FeaturedOn />

      <Subscription />
    </div>
  );
}
