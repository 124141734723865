import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import ProfileForm from './Components/ProfileForm';
import ChangePW from './Components/ChangePW';

// BasisID
// import ElementBasisID from "./Components/BasisId";

import Veriff from "./Components/Veriff";

import './styles/profile.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class Page extends Component {
  state = {
    profile: null,
    value: 0
  }

  componentWillMount() {
    const profile = JSON.parse(localStorage.getItem('user'))
    this.setState({ profile });
  }

  componentDidMount() {
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 500);
  }

  render() {
    const {profile, value} = this.state;

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    const isProfileFilled = (profile) => {
      const fields = ['address', 'address_proof']
      let result = false;

      fields.forEach(field => {
        if (result === false) {
          result = profile[field] === null || profile[field] === ""
        }
      });

      return result;
    }

    return (
      <div id="profile-form">
        <div className="spaceBottom">            
            <AppBar position="static">
              { <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Profile Form" {...a11yProps(0)} />
                <Tab label="Change Password" {...a11yProps(1)} />
                <Tab label="Veriff" {...a11yProps(2)} disabled={isProfileFilled(profile)} />
              </Tabs> }
            </AppBar>
            <TabPanel value={value} index={0} className="tab-p-0">
              <ProfileForm changeTab={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChangePW />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Veriff profile={profile} />
            </TabPanel>
          </div>
      </div>
    )
  }
}

export default Page;
