import React, { Component } from "react";
import { Link } from "react-router-dom";
import hp from "services/hp";

import './styles/referal.scss';

import BannerImage from 'images/referal/banner.png';
import ReferFlowImage from 'images/referal/refer-flow.png';
import ReferFlowMobile1Image from 'images/referal/refer-flow-m1.png';
import ReferFlowMobile2Image from 'images/referal/refer-flow-m2.png';
import ReferFlowMobile3Image from 'images/referal/refer-flow-m3.png';
import ReferFlowMobile4Image from 'images/referal/refer-flow-m4.png';
import ReferFlowMobile5Image from 'images/referal/refer-flow-m5.png';

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
  } from "@material-ui/core";
import { Helmet } from "react-helmet";

class Page extends Component {
    
    render() {
        function gotoDashboardRef()
        {
            window.location.href = "/dashboard/referral"
        }

        return (
            <>
                <Helmet>
                    <title>Referral | Kapital Boost</title>
                    {/* <meta name="description" content="Find answers to frequently asked questions about Kapital Boost, our Islamic P2P financing platform, and how we help SMEs." /> */}
                </Helmet>
                
                <div data-page="referal">
                    <section className="header-page">
                        <Grid className="section-header" container direction="row-reverse" justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item sm={12} md={6}>
                                <img src={BannerImage} width="100%" />
                            </Grid>
                            <Grid item sm={12} md={6} className="title">
                                <h1>
                                    Refer a friend and get rewarded
                                </h1>
                                <p>
                                Invite your friends to invest and earn SGD20 for every successful referral. Spread the growth of ethical investing and boost your earnings, one referral at a time. Please refer to the infographics below for more details.
                                </p>
                                {hp.is_login() ? (
                                    <button className="btn-kb btn-blue" type="button" onClick={() => window.location.replace('/dashboard/referral')}>
                                        Get Started
                                    </button>
                                ) : (
                                    <button className="btn-kb btn-blue" type="button" onClick={() => window.location.replace('/login')}>
                                        Get Started
                                    </button>
                                )}
                            </Grid>
                        </Grid>
                    </section>

                    <section className="refer-flow">
                        <Container>
                                <Hidden only={['xs', 'sm']}>
                                    <Grid container>
                                        <Grid xs={12} align="center">
                                            <h1>{ `Refer & be rewarded` }</h1>
                                            <img src={ReferFlowImage} className="refer-flow-image" />
                                        </Grid>
                                    </Grid>
                                </Hidden>

                                <Hidden mdUp>
                                    <Grid container className="mobile-view">
                                        <Grid xs={12} align="center">
                                            <h1>{ `Refer & be rewarded` }</h1>
                                        </Grid>

                                        <Grid xs={12} align="center" className="item">
                                            <img src={ReferFlowMobile1Image} />
                                        </Grid>
                                        <Grid xs={12} align="center" className="item">
                                            <img src={ReferFlowMobile2Image} />
                                        </Grid>
                                        <Grid xs={12} align="center" className="item">
                                            <img src={ReferFlowMobile3Image} />
                                        </Grid>
                                        <Grid xs={12} align="center" className="item">
                                            <img src={ReferFlowMobile4Image} />
                                        </Grid>
                                        <Grid xs={12} align="center" className="item">
                                            <img src={ReferFlowMobile5Image} />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                        </Container>
                    </section>

                    <section>
                        <Container>
                            <Grid className="refer-friend" container direction="row-reverse" justifyContent="space-between" alignItems="center">
                                <Grid xs={12} align="center">
                                    <h1>{ `Together, we can do much more in` } <br/> { `#fundingcommunitygrowth.` }</h1>
                                    <h4>{ `Invite your friends today` }</h4>
                                    <button className="btn-kb btn-orange" type="button" onClick={() => gotoDashboardRef()}>
                                        Get referral code
                                    </button>
                                </Grid>
                            </Grid>
                        </Container>
                    </section>
                </div>
            </>
        );
    }
}

export default Page;