import React, { Component, useState } from "react";

import './styles/global.scss';

import BannerImageWeb from 'images/get-funded/banner.jpg';
import SolutionImage from 'images/get-funded/solution-image.png';
import Proccess1Image from 'images/get-funded/proccess-1.png';
import Proccess2Image from 'images/get-funded/proccess-2.png';
import Proccess3Image from 'images/get-funded/proccess-3.png';
import transparentCostImage from 'images/get-funded/transparent-cost.png';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

import GetFundedForm from "./components/GetFundedForm"

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
  } from "@material-ui/core";
import { Helmet } from "react-helmet";

  export default function GetFunded() {

    return (
        <>
            <Helmet>
                <title>Get Funded | Kapital Boost Islamic P2P Financing Solutions</title>
                <meta name="description" content="Learn how to get funded through Kapital Boost's Islamic P2P financing platform. Discover our simple application process and eligibility criteria." />
            </Helmet>

            <div data-page="get-funded">
                <div className="header">
                    <img className="header-background" alt="header for about us" src={BannerImageWeb} srcSet={`${BannerImageWeb} 768w, ${BannerImageWeb} 1280w`} loading="lazy" />

                    <div className="header-body">
                        <Container>
                            <h1>The convenience of funding <br/>starts here!</h1>
                        </Container>
                    </div>
                </div>

                <section className="content-body">
                    <div className="blue p-2">
                        <Container>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className="mui-justify-center">
                            <Grid md={4}>
                                <p className="text-center fs-3 text-white">Up to SGD200,000 </p>
                                </Grid> 
                            <Grid md={4}>
                                <p className="text-center fs-3 text-white">Rates starting from 1.2%<br/>per month  </p>
                                </Grid> 
                            <Grid md={4}>
                                <p className="text-center fs-3 text-white">1–6 months tenor </p>
                                </Grid> 
                            </Grid>
                        </Container>
                    </div>

                    <div className="grey p-4">
                        <Container>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className="mui-justify-center">
                                <Grid xs={10} md={9}>
                                    <p className="text-center fs-2">
                                        We understand that small businesses face a big challenge in obtaining financing to grow the business or overcome a temporary cash flow gap. With our short-term financing solutions, you can worry less and focus more on your business.  
                                    </p>
                                </Grid> 
                            </Grid>
                        </Container>
                    </div>

                    <div className="py-2">
                        <Container>
                            <p className="title text-center">Our financing solutions</p>

                            <div className="solution">
                                <img src={SolutionImage} alt="Our financing solutions" />
                                
                                <div className="item">
                                    <div className="card blue">
                                        <h4>Asset Purchase Financing</h4>
                                        <ul>
                                            <li>Financing period between 2 – 6 months </li>
                                            <li>Has an existing purchase order (PO) to complete</li>
                                            <li>Use of funds to pay suppliers only</li>
                                            <li>Disbursement of funds to supplier directly</li>
                                        </ul>
                                    </div>

                                    <div className="card">
                                        <h4>Invoice Financing</h4>
                                        <ul>
                                            <li>Tenor between 1 – 4 months </li>
                                            <li>Has invoices issued to customers such as MNCs, public listed or state-owned enterprises</li>
                                            <li>Use of funds for any short-term working capital needs</li>
                                            <li>Disbursement of funds to SME </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="proccess py-2">
                        <Container>
                            <p className="title text-center">How the crowdfunding process works</p>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flext-start"
                                >
                                    <Grid xs={12} md={4}>
                                        <Paper elevation={0} className="p-2 text-center">
                                            <img src={Proccess1Image} alt={`Meet the requirements`} />

                                            <div>
                                                <p className="item-title">Meet the requirements</p>

                                                <ul className="text-left">
                                                    <li>Incorporated in Singapore or Indonesia</li>
                                                    <li>More than 1 year of operating history</li>
                                                    <li>More than SGD100,000 in annual sales</li>
                                                </ul>
                                            </div>
                                        </Paper>
                                    </Grid>

                                    <Grid xs={12} md={4}>
                                        <Paper elevation={0} className="p-2 text-center">
                                            <img src={Proccess2Image} alt={`Due Diligence & Verification`} />

                                            <div>
                                                <p className="item-title">Due Diligence & Verification</p>

                                                <ul className="text-left">
                                                    <li>Verification of submitted data from SME</li>
                                                    <li>Credit risk analysis to determine risk and rates</li>
                                                    <li>Determine funding limit and tenor</li>
                                                </ul>
                                            </div>
                                        </Paper>
                                    </Grid>

                                    <Grid xs={12} md={4}>
                                        <Paper elevation={0} className="p-2 text-center">
                                            <img src={Proccess3Image} alt={`Get Funded`} />

                                            <div>
                                                <p className="item-title">Get Funded</p>

                                                <ul className="text-left">
                                                    <li>Launch of SME campaign on website</li>
                                                    <li>Crowdfunding from investors and e-signing of contracts</li>
                                                    <li>Funds are disbursed within 7 days</li>
                                                </ul>
                                            </div>
                                        </Paper>
                                    </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div className="transparent-cost py-3 grey">
                        <Container>
                            <p className="title text-center">Transparent costs</p>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                >
                                    <Grid xs={12} md={7}>
                                        <img src={transparentCostImage} alt={`Transparent cost`} width="100%" />
                                    </Grid>
                                    <Grid xs={12} md={5}>
                                        <ul>
                                            <li>One-time platform fees for the funds you raised</li>
                                            <li>Flat monthly rate to investors as low as 1.2%</li>
                                            <li>No penalty for early repayment</li>
                                            <li>No other fees</li>
                                        </ul>
                                    </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div className="py-2">
                        <Container>
                            <p className="title text-center">Ready to apply for financing?</p>
                            
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                className="mui-justify-center"
                                >
                                    <Grid xs={12} md={6}>
                                        <p className="fs-2 text-center">Submit your particulars and our team will get in touch with you shortly to discuss your company’s eligibility for financing. </p>

                                    </Grid>
                                    <Grid xs={12} md={8}>
                                        <GetFundedForm />
                                    </Grid>
                                </Grid>
                        </Container>
                    </div>

                    <div className="grey py-2">
                        <Container>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                >
                                    <Grid xs={12} md={6}>
                                        <p className="fs-1">Have questions on our funding process and requirements? View our FAQ page or contact us at </p>
                                    </Grid>
                                    <Grid xs={12} md={2}>&nbsp;</Grid>
                                    <Grid xs={12} md={4}>
                                        <p className="fs-1"><PhoneIcon /> 8433 1760</p>
                                        <p className="fs-1"><MailIcon /> hello@kapitalboost.com</p>
                                    </Grid>
                            </Grid>
                        </Container>
                    </div>

                </section>
            </div>
        </>
    );
}