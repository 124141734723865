import React, { Component } from "react";
import './styles/howitworks.scss';
import PropTypes from 'prop-types';
import {AppBar, Grid, Tabs, Tab, Box, Container, Paper  } from "@material-ui/core";
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import BannerImage from '../images/how-it-works/banner.png';
import AssetFlow1 from '../images/how-it-works/AP1.png';
import AssetFlow2 from '../images/how-it-works/AP2.png';
import AssetFlow3 from '../images/how-it-works/AP3.png';
import AssetFlow4 from '../images/how-it-works/AP4.png';
import AssetFlow5 from '../images/how-it-works/AP5.png';
import Invoice1 from '../images/how-it-works/IF1.png';
import Invoice2 from '../images/how-it-works/IF2.png';
import Invoice3 from '../images/how-it-works/IF3.png';
import Invoice4 from '../images/how-it-works/IF4.png';
import Invoice5 from '../images/how-it-works/IF5.png';
import Flow1 from '../images/how-it-works/Flow1.png';
import Flow2 from '../images/how-it-works/Flow2.png';
import Flow3 from '../images/how-it-works/Flow3.png';
import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="py-2">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function HowItWorks() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <>
      <Helmet>
        <title>How It Works | Kapital Boost Islamic P2P Financing</title>
        <meta name="description" content="Discover how Kapital Boost's Islamic P2P financing platform works. Learn about our simple, transparent process for funding and investing." />
      </Helmet>

      <div data-page="how-it-works">
        <div className="banner">
          <img src={BannerImage} alt="How it Works" />

          <div className="content">
            <div>
              <h1>Fund the growth of SMEs and earn annualized returns of up to 22%</h1>
            </div>
          </div>
        </div>

        <section className="bg-silver tab py-4">
          <Container>
            <p className="title center space-bottom">Know our funding types</p>
            
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid xs='6'><button className={`tab-button ${value === 1 ? 'active':''}`} onClick={(e) => handleChange(e, 1)}>Invoice Financing</button></Grid>
              <Grid xs='6' className="text-right"><button className={`tab-button ${value === 0 ? 'active':''}`} onClick={(e) => handleChange(e, 0)}>Asset Purchase Financing</button></Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                  <TabPanel value={value} index={0}>
                    <p className="text">
                      Also known as Murabaha or cost-plus financing, investors pool funds to purchase assets (such as inventories and raw materials) which they will then sell to the SME at a markup. The SME then pays investors the cost-plus markup amount at an agreed future date. To ensure availability of cash flow on payment date, the SME is required to have an existing purchase order from customers. 
                    </p>
                    <br />
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="justify-center"
                    >
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={AssetFlow1} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={AssetFlow2} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={AssetFlow3} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={AssetFlow4} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={AssetFlow5} width="100%" />
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <p className="text">
                      Backed by an invoice that is awaiting payment from customer, investors pool funds to offer a cash advance (based on qard structure) on the unpaid invoice. At the same time, investors are appointed as agent to collect on the invoice (based on wakalah structure).
                    </p>
                    <br />
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="justify-center"
                    >
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={Invoice1} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={Invoice2} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={Invoice3} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={Invoice4} width="100%" />
                        </div>
                      </Grid>
                      <Grid xs="12" sm="6" md="4">
                        <div className="p-2">
                          <img src={Invoice5} width="100%" />
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="flow-invest py-4">
          <Container>
            <p className="title center space-bottom">How to invest on Kapital Boost</p>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid xs="12" sm="6" md="4">
                <div className="px-2">
                  <img src={Flow1} width="100%" />

                  <p className="text-title text-center">
                    Browse Funding Campaigns
                  </p>

                  <div className="list-items">
                    <ul>
                      <li>Browse SME funding campaigns across different sectors and countries</li>
                      <li>Invest from as low as SGD200 on short- term, legally-structured funding opportunities 
                        {/* {`with attractive returns`} */}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid xs="12" sm="6" md="4">
                <div className="px-2">
                  <img src={Flow2} width="100%" />

                  <p className="text-title text-center">
                    Conduct Due Diligence & Analysis
                  </p>

                  <div className="list-items">
                    <ul>
                      <li>Conduct your own analysis with the detailed information provided</li>
                      <li>Have questions or require additional information? Contact us at <a href="mailto:support@kapitalboost.com">support@kapitalboost.com</a></li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid xs="12" sm="6" md="4">
                <div className="px-2">
                  <img src={Flow3} width="100%" />

                  <p className="text-title text-center">
                    Fund SMEs, Earn Attractive Returns
                  </p>

                  <div className="list-items">
                    <ul>
                      <li>Determine the amount you want to invest</li>
                      <li>Earn annualised return on investments of 15 - 22%</li>
                      <li>Support community growth by funding SMEs</li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="invest-step bg-silver py-4">
          <Container>
            <p className="title center space-bottom">Invest in 4 simple steps</p>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid xs="12" sm="6" md="3">
                <div className="px-1 mb-2">
                  <Paper elevation={3} className="paper p-2 text-center">
                    <span className="circle">1</span>
                    <p className="text">Register an account and complete the e-KYC process </p>
                  </Paper>
                </div>
              </Grid>
              <Grid xs="12" sm="6" md="3">
                <div className="px-1 mb-2">
                  <Paper elevation={3} className="paper p-2 text-center">
                    <span className="circle">2</span>
                    <p className="text">Upon account approval, gain access to detailed SME campaigns</p>
                  </Paper>
                </div>
              </Grid>
              <Grid xs="12" sm="6" md="3">
                <div className="px-1 mb-2">
                  <Paper elevation={3} className="paper p-2 text-center">
                    <span className="circle">3</span>
                    <p className="text">Invest in the SME campaign of your choice</p>
                  </Paper>
                </div>
              </Grid>
              <Grid xs="12" sm="6" md="3">
                <div className="px-1 mb-2">
                  <Paper elevation={3} className="paper p-2 text-center">
                    <span className="circle">4</span>
                    <p className="text">Sign investment contract and pay securely with digital ease</p>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        
        <section className="py-4">
          <Container className="text-center">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="justify-center"
          >
            <Grid xs={12} md={10} lg={6}>
              <p className="title center space-bottom no-line-height">Be a part of the community that is #fundingcommunitygrowth</p>

              <button className="btn-kb btn-orange px-5" type="button" onClick={() => window.location.href = '/registration'}>
                  Register as Investor
              </button>
            </Grid>
          </Grid>
          </Container>
        </section>
      </div>
    </>
  );
}